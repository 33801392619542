import React, { useState, useEffect } from 'react';
import { Grid, Button, Box, Paper, TextField, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment';

// styles
import useStyles from './styles';

// components
import PageTitle from '../../components/PageTitle/PageTitle';
import useCommonOptions from '../../hooks/useCommonOptions';
import useQueryParams from '../../hooks/useQueryParams';
import commonApi from '../../services/common.service';

import companyApi from '../../services/company.service';
import CustomTable from '../../components/CustomTable';
import CustomMenu from '../../components/CustomMenu';

const companyPublishStatusOptions = [
  {
    value: 1,
    label: 'Draft',
  },
  {
    value: 3,
    label: 'Published',
  },
  {
    value: 5,
    label: 'Editing',
  },
  {
    value: 6,
    label: 'Terminated',
  },
];

const client_company_id = '1';
const client_shareholder_company_id = '2';
const client_auditor_company_id = '3';

const companyCategoryObject = {
  [client_company_id]: 'Client Company',
  [client_shareholder_company_id]: "Client's Shareholder Company",
  [client_auditor_company_id]: "Client's Auditor Company",
};

const companyCategoryOptions = Object.keys(companyCategoryObject).map((aKey) => {
  return {
    label: companyCategoryObject[aKey],
    value: aKey,
  };
});

// [
//   {
//     label: 'Client Company',
//     value: client_company_id,
//   },
//   {
//     label: "Client's Shareholder Company",
//     value: client_shareholder_company_id,
//   },
//   {
//     label: "Client's Auditor Company",
//     value: client_auditor_company_id,
//   },
// ];

export default function Company(props) {
  var classes = useStyles();
  var theme = useTheme();
  const history = useHistory();
  const queryParams = useQueryParams();

  // local
  const [isLoading, setLoading] = useState(true);
  var [companyList, setCompanylist] = useState([]);

  const { options: companyTypeOptions, loading: loadingCompanyTypes } = useCommonOptions(
    commonApi.getCompanyType,
    {
      respondKey: 'company_types',
      labelKey: 'company_type_name',
      valueKey: 'company_type_id',
    }
  );

  const { options: companyStatusOptions, loading: loadingCompanyStatuses } = useCommonOptions(
    commonApi.getCompanyStatus,
    {
      respondKey: 'company_statuses',
      labelKey: 'company_status_name',
      valueKey: 'company_status_id',
      render: (value, data) => {
        return `${data?.status_category} - ${data?.company_status_name}`;
      },
    }
  );

  let loading = isLoading || loadingCompanyTypes || loadingCompanyStatuses;

  const filters = [
    {
      label: 'Company Name / Registration No',
      valueKey: 'company_name',
      type: 'text',
    },

    {
      label: 'Company Type',
      valueKey: 'company_type_id',
      type: 'select',
      options: companyTypeOptions,
    },
    {
      label: 'Company Status',
      valueKey: 'company_status_id',
      type: 'select',
      options: companyStatusOptions,
    },
    {
      label: 'Publish Status',
      valueKey: 'publish_status_id',
      type: 'select',
      options: companyPublishStatusOptions,
    },
    {
      label: 'Company Client Type',
      valueKey: 'company_client_type_id',
      type: 'toggle',
      options: companyCategoryOptions,
    },
  ];

  let filterValues = {};
  filters.forEach((anItem) => {
    let value = queryParams.get(anItem.valueKey);
    if (value) {
      filterValues[anItem.valueKey] = queryParams.get(anItem.valueKey);
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const respCompanieslist = await companyApi.getCompanies('', 1, 10000);
        let companies = respCompanieslist?.data?.companies || [];
        companies.sort((a, b) => {
          return b.company_id - a.company_id;
        });

        setCompanylist(companies);
      } catch (error) {
        setCompanylist([]);
      }
    };

    fetchData().finally(() => setLoading(false));
  }, []);

  const handleFilterOnChange = (key, value) => {
    if (queryParams.get(key) == value) {
      queryParams.delete(key);
    } else {
      queryParams.set(key, value);
    }
    let newQueryParams = queryParams.toString();
    history.replace({
      pathname: '/company',
      search: '?' + newQueryParams,
    });
  };

  const handleClearFilter = () => {
    history.replace({
      pathname: '/company',
    });
  };

  let filteredCompanies = companyList.filter((aCompany) => {
    let filterKeys = Object.keys(filterValues);
    let result = true;

    filters.forEach((aFilter) => {
      let filterKey = aFilter.valueKey;
      let filterKeyValue = filterValues?.[filterKey];
      if (filterKeyValue != undefined && filterKeyValue != null && filterKeyValue != '') {
        if (aFilter.type == 'text') {
          let lowercaseFilterValue = (filterKeyValue || '').toLocaleLowerCase();
          let foundName =
            (aCompany?.['company_name'] || '').toLocaleLowerCase().indexOf(lowercaseFilterValue) >=
            0;
          let foundRegisterNumber =
            (aCompany?.['company_registration_no'] || '')
              .toLocaleLowerCase()
              .indexOf(lowercaseFilterValue) >= 0;
          if (!foundName && !foundRegisterNumber) {
            result = false;
          }
        } else {
          if (aCompany[filterKey] != filterKeyValue) {
            result = false;
          }
        }
      }
    });

    return result;
  });

  const tableColumns = [
    {
      label: 'ID',
      id: 'company_id',
    },
    {
      label: 'COMPANY NAME',
      id: 'company_name',
    },
    {
      label: 'COUNTRY OF INCORPORATION',
      id: 'country_of_incorporation',
    },
    {
      label: 'INCORPORATION DATE',
      id: 'incorporation_date',
      render: (text) => {
        return moment(text).format('DD MMM YYYY');
      },
    },
    {
      label: 'COMPANY TYPE',
      id: 'company_type_name',
    },
    {
      label: 'COMPANY CLIENT TYPE',
      id: 'company_client_type_id',
      render: (text) => {
        let result = '-';
        if (text) {
          result = companyCategoryObject?.[text] || text;
        }
        return result;
      },
    },
    {
      label: 'COMPANY REGISTRATION NO',
      id: 'company_registration_no',
    },
    {
      label: 'COMPANY STATUS',
      id: 'company_status_name',
    },
    {
      label: 'PUBLISH STATUS',
      id: 'publish_status_name',
      render: (text) => {
        return text || 'Draft';
      },
    },
  ];

  return (
    <>
      <Button
        size="medium"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        onClick={() => history.push({ pathname: '/dashboard' })}
      >
        BACK TO DASHBOARD
      </Button>
      <PageTitle
        title="Companies"
        button={
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={() => {
              history.push({
                pathname: '/companyform',
                //   search: "?company=" + company_id,
                state: {
                  // company_id: localStorage.getItem("company_id"),
                  // attachment_type_id: "1",
                  // role_id: post.role_id,
                  // company_name: localStorage.getItem("company_name"),
                  // attachment_type_name: "Appointment Letter",
                  // role_name: post.title,
                },
              });
            }}
          >
            Add New Company
          </Button>
        }
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Box mb={2} border={1} p={2} borderRadius={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Box mr={2}>
                        <b>Filter by: </b>
                      </Box>
                      {Object.keys(filterValues)?.length > 0 ? (
                        <Box>
                          <Button
                            onClick={handleClearFilter}
                            size="small"
                            color={'secondary'}
                            variant="outlined"
                          >
                            Clear Filter
                          </Button>
                        </Box>
                      ) : null}
                    </Box>
                  </Grid>
                  {filters.map((anItem, index) => {
                    if (anItem.type == 'text') {
                      return (
                        <Grid key={index} item xs={12} md={6}>
                          <TextField
                            label={anItem.label}
                            variant="outlined"
                            value={filterValues?.[anItem.valueKey] || ''}
                            onChange={(e) => {
                              let value = e.target.value;
                              handleFilterOnChange(anItem.valueKey, value);
                            }}
                            fullWidth={true}
                            size="small"
                          />
                        </Grid>
                      );
                    } else if (anItem.type == 'toggle') {
                      return (
                        <Grid item xs={12} key={index}>
                          <Box mb={1}>
                            <small>{anItem.label}</small>
                          </Box>
                          <ButtonToggleGroup
                            options={anItem.options}
                            value={filterValues?.[anItem.valueKey] || ''}
                            // label="Status"
                            onChange={(value) => {
                              handleFilterOnChange(anItem.valueKey, value);
                            }}
                            variant="outlined"
                          />
                        </Grid>
                      );
                    } else if (anItem.type == 'select') {
                      return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <CustomMenu
                            label={anItem.label}
                            data={anItem.options}
                            value={filterValues?.[anItem.valueKey] || ''}
                            // label="Status"
                            onChange={(e) => {
                              let value = e.target.value;
                              handleFilterOnChange(anItem.valueKey, value);
                            }}
                            variant="outlined"
                            size="small"
                            // searchable={true}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                </Grid>
              </Box>
              <Paper component={Box}>
                <CustomTable
                  columns={tableColumns}
                  data={filteredCompanies}
                  rowKey="company_id"
                  onClickRow={(company_id) => {
                    history.push({
                      pathname: '/companydetails',
                      search: '?company=' + company_id,
                      state: {},
                    });
                  }}
                />
              </Paper>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

const ButtonToggleGroup = (props) => {
  const { options = [], value, onChange } = props;

  return (
    <Box display="flex" flexWrap="wrap">
      {options.map((anItem, index) => {
        let isActive = anItem.value == value;
        return (
          <Box key={index} mr={1} mb={1}>
            <Button
              disableElevation={true}
              variant={isActive ? 'contained' : 'outlined'}
              color={'primary'}
              onClick={(e) => {
                onChange(anItem.value);
              }}
            >
              {anItem.label}
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};
